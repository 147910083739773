import { createApp } from 'vue';
import { createPinia } from "pinia";
import App from './App.vue';
import router from "./router";

//引入element
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "./assets/css/admin.css";


const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(ElementPlus, { locale: zhCn });
app.mount('#app');
