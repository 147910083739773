import { CategoryData } from "@/shared/types/CategoryData";
import {set,get} from "js-cookie";

export function setTitle  (title: string):void {
  window.document.title = `${title}-管理系统` || "管理系统";
}
export function isNumber(str: string): boolean {
  return /^\d+$/.test(str);
}
const urlPattern = /^(http:\/\/|https:\/\/)/;

export function isUrl(str: string): boolean {
  return urlPattern.test(str);
}
export function setCookie  (tokenName = "token", token: string):void  {
  set(tokenName, token);
}

export function getCookie  (tokenName = "token"):string|undefined  {
  return get(tokenName);
}

//无限极分类 适配 element-ui组件
export function addLabelValue  (arr: any[]):any[]  {
  for (const item of arr) {
    item["label"] = item["title"] as string;
    item["value"] = item["id"];
    if (item.children) {
      item.children = addLabelValue(item.children);
    }
  }
  return arr;
}

// // 无限极分类tree
export function tree  (arr: any[], pid = 0, leval = 1): any[]  {
  const tmp: any[] = [];
  arr.forEach((item) => {
    if (item.pid == pid) {
      item.leval = leval;
      item.children = tree(arr, item.id, leval + 1);
      tmp.push(item);
    }
  });
  return tmp;
}

//返回id父级所有栏目
export function treeById (id: number, source: CategoryData[]):number[]  {
  const arr: number[] = [];
  const findId = (id: number, source:CategoryData[]) => {
    let item;
    for (let i = 0; i < source.length; i++) {
      item = source[i];
      if (item.id == id) {
        arr.unshift(item.id);
        if (item.pid != 0) {
          findId(item.pid, source);
        }
      }
    }
  };
  findId(id, source);
  return arr;
}

// html过滤出图片
export function getImgUrlFromStr  (str: string):string[]{
  const imgReg = /<img.*?(?:>|\/>)/gi; //匹配出图片img标签
  // eslint-disable-next-line no-useless-escape
  const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i; //匹配出图片src属性
  const arr = str.match(imgReg);
  const imgArr = [];
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      const src = arr[i].match(srcReg) as string[];
      if (src[1]) {
        imgArr.push(src[1]);
      }
    }
  }
  return imgArr;
}

// 过滤 html标签
export function filterHtml (str: string):string  {
  return str.replace(/<[^>]*>/g, "");
}

// 过滤 body标签
export function filterBody(str: string):string{
  const result = /<body[^>]*>([\s\S]*)<\/body>/.exec(str);
  if (result && result.length === 2) return result[1];
  return str;
}
export async function loadFile(file: File): Promise<Uint8Array> {
    return new Promise(rs => {
      const reader = new FileReader();
        reader.onload = e => {
            rs(new Uint8Array(e.target!.result as ArrayBuffer))
        }
        reader.readAsArrayBuffer(file);
    });
}
