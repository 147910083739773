
import { defineComponent } from "vue";
export default  defineComponent({
  name: "App",
  data() {
    return {
    };
  },
  methods:{
  }
});
